<div class="row" id="tablecontent" *ngIf="!isQuotation">
  <div
    class="col col-lg-4 col-md-6 col-sm-8 col-xs-12"
    *ngIf="ordersummary | async as ordersummary">
    <div class="form-group">
      <label class="label-text"
        ><b>{{ "orderDetail.totalCost" | cxTranslate }} </b></label
      >
      <div class="row">
        <div class="col col-6">
          <label class="order-summary-label"
            >{{ "orderDetail.subtotal" | cxTranslate }}
          </label>
        </div>
        <div class="col col-6">
          <p *ngIf="!ordersummary?.subTotal" class="information-text text-right"></p>
          <p *ngIf="ordersummary?.subTotal" class="information-text text-right">
            {{ ordersummary?.subTotal?.value | currencyPrice }}
            {{ findSymbol(ordersummary?.subTotal?.currencyIso) }}
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col col-6">
          <label class="order-summary-label">{{
            "orderDetail.transportFee" | cxTranslate
          }}</label>
        </div>
        <div class="col col-6">
          <p *ngIf="!ordersummary?.transportFee" class="information-text text-right"></p>
          <p *ngIf="ordersummary?.transportFee"class="information-text text-right">
            {{ ordersummary?.transportFee?.value | currencyPrice }}
            {{ ordersummary?.transportFee?.currencyIso | currencyFormat }}
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col col-6">
          <label class="order-summary-label">{{
            "orderDetail.shippingFee" | cxTranslate
          }}</label>
        </div>
        <div class="col col-6">
          <p *ngIf="!ordersummary?.shippingFee" class="information-text text-right"></p>
          <p *ngIf="ordersummary?.shippingFee" class="information-text text-right">
            {{ ordersummary?.shippingFee?.value | currencyPrice }}
            {{ ordersummary?.shippingFee?.currencyIso | currencyFormat }}
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col col-6">
          <label class="order-summary-label">{{
            "orderDetail.taxes" | cxTranslate
          }}</label>
        </div>
        <div class="col col-6">
          <p *ngIf="!ordersummary?.totalTax" class="information-text text-right"></p>
          <p *ngIf="ordersummary?.totalTax" class="information-text text-right">
            {{ ordersummary?.totalTax?.value | currencyPrice }}
            {{ ordersummary?.totalTax?.currencyIso | currencyFormat }}
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col col-6">
          <label class="order-summary-label">{{
            "orderDetail.grandTotal" | cxTranslate
          }}</label>
        </div>
        <div class="col col-6">
          <p *ngIf="!ordersummary?.totalPriceWithTax" class="information-text text-right"></p>
          <p *ngIf="ordersummary?.totalPriceWithTax" class="information-text text-right">
            {{ ordersummary?.totalPriceWithTax?.value | currencyPrice }}
            {{ ordersummary?.totalPriceWithTax?.currencyIso | currencyFormat }}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
